.container-count {
  border-radius: 50%;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: .2rem;
  font-size: .8rem;
  padding: 1rem;
  border: 3px solid var(--ion-color-tertiary)
}

.container-count p {
  margin: 0;
  padding: 0;
  font-size: .8rem;
}