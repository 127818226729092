@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600;700&display=swap');
    
*::-webkit-scrollbar {
  width: 6px !important;     /* Tamaño del scroll en vertical */
  height: 6px !important;   /* Tamaño del scroll en horizontal */
}

*::-webkit-scrollbar-thumb {
  background: var(--ion-color-primary) !important;
  border-radius: 4px !important;
}  

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
*::-webkit-scrollbar-thumb:hover {
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Ponemos un color de fondo y redondeamos las esquinas del track */
*::-webkit-scrollbar-track {
  background-color: white !important;
  border-radius: 4px;
}

/* * ::-webkit-scrollbar {
  display: none;
} */

:root {
  --z-back: -1;
  --z-normal: 1;
  --z-fixed: 1000;
}

body {
  --ion-font-family: 'Outfit', sans-serif;
}

ion-button:not(.ios):not(.button-small) {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
}

ion-label a {
  color: var(--ion-color-secondary);
}

ion-icon {
  cursor: pointer;
}

ion-icon:hover {
  cursor: pointer;
}

.form {
  width: 100%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 700px) {
  .form {
    width: 100%;
    min-width: 600px;
  }
}

ion-textarea, textarea {
  min-height: 150px;
}

.input {
  background-color: var(--ion-item-background);
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.flex {
  display: flex;
}

p {
  font-size: 16px;
}

p a {
  color: var(--ion-color-secondary);
}

ion-segment.form-segment:not(.ios) {
  padding: .2rem .2rem .1rem;
  background-color: var(--ion-color-primary);
  color: white;
  border-radius: 20px;
}

ion-segment.form-segment:not(.ios) ion-segment-button {
  border-radius: 20px;
  --color: white;
  font-size: 12px;
}

ion-segment.form-segment:not(.ios) ion-segment-button[aria-selected="true"] {
  color: white;
  --background-checked: var(--ion-color-secondary);
  --color-hover: white;
}

ion-modal ion-backdrop {
  height: 200vh;
  overflow: scroll !important; 
}

/* Modal small type action sheet */
ion-modal.ios.modal-small {
  color: var(--ion-color-datetime);
}

ion-modal.modal-small {
  --width: fit-content;
  --min-width: 300px;
  --height: fit-content;
  color: var(--ion-color-datetime);
  --border-radius: 28px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --margin: 10px;
  padding: 1rem;
  --background: var(--ion-item-background);
}

ion-datetime {
  --background: var(--ion-item-background);
  color: var(--ion-color-dark) !important;
}

@media screen and (min-width: 600px) {
  ion-modal.modal-small.large {
    --min-width: 600px;
  }
}

ion-card.md.box {
  background-color: var(--ion-card-background);
  border-radius: 28px;
  z-index: var(--z-fixed);
}

.grid-input.md, .ios {
  --ion-grid-padding: 0;
}

.grid-input.md ion-col.md, .ios {
  --ion-grid-column-padding: 0;
}

/* size of swiper */
.swiper-inspection .swiper-wrapper.swiper-wrapper {
  width: 100%;
  height: calc(100vh - 70px) !important;
}

.swiper-inspection.swiper-steps .swiper-wrapper {
  height: calc(100vh - 200px) !important;
}

.swiper-inspection.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
  width: 100%;
  max-height: 100vh !important;
  height: calc(100vh - 70px) !important;
  overflow-x: hidden;
  display: flex;
  align-items: flex-start;
}

.swiper-inspection.swiper-steps.swiper-container-autoheight, .swiper-inspection.swiper-steps.swiper-container-autoheight .swiper-slide {
  height: calc(100vh - 200px) !important;
}

capacitor-google-map {
  display: inline-block;
  width: 100%;
}

ion-split-pane {
  --side-max-width: 12%;
  --side-min-width: 270px;
}